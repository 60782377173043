/* Results Component */
#results {
  padding: 100px 0;
  overflow-x: hidden;
}
#results .container {
  max-width: 1024px;
  margin: 0 auto;
}
#results .stats {
  margin-left: -15px;
}
#results .stat-item {
  display: inline-block;
  padding: 15px 35px;
}
#results .stat-item .title {
  font-weight: bold;
}
#results table {
  border-spacing: 20px;
  border-collapse: separate;
}
#results table th {
  font-size: 32px;
}
#results table td {
  vertical-align: top;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  padding: 12px;
  border-radius: 4px;
}
#results .processed-links .failed .failed-item,
#results .processed-links .passed .passed-item {
  margin-top: 5px;
  border-top: 1px solid #ffffff;
  padding-top: 5px;
}
#results .processed-links .failed .status {
  background-color: #ab0000;
  color: #ffffff;
  padding: 2px 5px;
  display: inline-block;
}
#results .processed-links .passed .status {
  background-color: #9acd32;
  color: #ffffff;
  padding: 2px 5px;
  display: inline-block;
}
#results .processed-links > div {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  padding: 12px;
  border-radius: 4px;
  font-size: 12px;
  margin-top: 15px;
  max-height: 300px;
  overflow-y: auto;
}
#results .btn-container {
  margin-top: 25px;
}
