.serp.google {
  font-family: Roboto, arial, sans-serif;
  border: 1px solid #eaeaea;
  padding: 25px;
  border-radius: 4px;
  margin: 15px 0 35px;
}
.serp.google .url {
  font-size: 14px;
  padding-top: 1px;
  line-height: 1.3;
  color: #202124;
}
.serp.google .title {
  font-size: 20px;
  line-height: 1.3;
  margin: 5px 0;
}
.serp.google .title a {
  color: #1a0dab;
}
.serp.google .date {
  color: #70757a;
  font-size: 14px;
  margin-right: 5px;
}
.serp.google .meta-description {
  line-height: 1.58;
  color: #4d5156;
  font-size: 14px;
}

/*
* Stars
*/
.stars > span {
  color: #d1d1d1;
  font-size: 13px;
}

[data-rating="1"].stars > span:first-child,
[data-rating="1.1"].stars > span:first-child,
[data-rating="1.2"].stars > span:first-child,
[data-rating="1.3"].stars > span:first-child,
[data-rating="1.5"].stars > span:first-child,
[data-rating="1.6"].stars > span:first-child,
[data-rating="1.7"].stars > span:first-child,
[data-rating="1.8"].stars > span:first-child,
[data-rating="1.9"].stars > span:first-child {
  color: #fabb05;
}

[data-rating="2"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.1"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.2"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.3"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.5"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.6"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.7"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.8"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child),
[data-rating="2.9"].stars
  > span:not(:nth-child(3)):not(:nth-child(4)):not(:last-child) {
  color: #fabb05;
}

[data-rating="3"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.1"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.2"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.3"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.5"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.6"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.7"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.8"].stars > span:not(:nth-child(4)):not(:last-child),
[data-rating="3.9"].stars > span:not(:nth-child(4)):not(:last-child) {
  color: #fabb05;
}

[data-rating="4"].stars > span:not(:last-child),
[data-rating="4.1"].stars > span:not(:last-child),
[data-rating="4.2"].stars > span:not(:last-child),
[data-rating="4.3"].stars > span:not(:last-child),
[data-rating="4.5"].stars > span:not(:last-child),
[data-rating="4.6"].stars > span:not(:last-child),
[data-rating="4.7"].stars > span:not(:last-child),
[data-rating="4.8"].stars > span:not(:last-child),
[data-rating="4.9"].stars > span:not(:last-child) {
  color: #fabb05;
}

[data-rating="5"].stars > span {
  color: #fabb05;
}

/*
* Recipe
*/
.recipe {
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  overflow: hidden;
}
.recipe .image {
  position: relative;
  height: 210px;
  overflow: hidden;
}
.recipe .image img {
  position: absolute;
  left: 0;
  top: 0;
}
.recipe .text {
  padding: 16px;
}
.recipe .title {
  color: #1a0dab;
  display: -webkit-box;
  font: 16px arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.recipe .author {
  font-size: 12px;
  margin-bottom: 5px;
}
.recipe .rating {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-right: 5px;
}
.recipe .time {
  color: #757575;
  font: 12px arial, sans-serif;
  margin: 5px 0;
}
.recipe .count {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-left: 5px;
}
.recipe .description {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-top: 5px;
}

/*
* Claim Review / Fact Check
*/
.claim-review {
  border: 1px solid #eaeaea;
  padding: 15px;
  font-size: 14px;
}
.claim-review .bottom > *:not(:last-child) {
  margin-bottom: 5px;
}
.claim-review .claim > span:first-child,
.claim-review .claimed-by > span:first-child {
  color: #727272;
}
.claim-review .fact-check-by {
  margin-top: 10px;
}
.claim-review .fact-check-by > *:first-child {
  color: #262dbb;
  font-weight: 500;
}

/*
* Course
*/
.course .title {
  font-size: 16px;
}
.course .description {
  font-size: 14px;
  margin-top: 5px;
}
.course .organization {
  color: #919191;
  margin-top: 3px;
  font-size: 14px;
}

/*
* Software Application
*/
.software-app {
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
}
.software-app .title {
  color: #1a0dab;
  display: -webkit-box;
  font: 16px arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.software-app .rating {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-right: 5px;
}
.software-app .time {
  color: #757575;
  font: 12px arial, sans-serif;
  margin: 5px 0;
}
.software-app .count {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-left: 5px;
}
.software-app .price > span,
.software-app .platforms > span {
  color: #757575;
  font: 12px arial, sans-serif;
}
.software-app .description {
  color: #757575;
  font: 12px arial, sans-serif;
  margin-top: 5px;
}
.software-app .rating-info {
  width: 50%;
}
.software-app .price,
.software-app .platforms {
  width: 25%;
}
.software-app .flex-container {
  margin-top: 10px;
}
.software-app .label {
  font-size: 14px;
}

/*
* Article
*/
.article {
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  box-shadow: none;
  overflow: hidden;
  max-width: 210px;
}
.article .text {
  padding: 15px;
}
.article .title {
  font-size: 16px;
  line-height: 1.25em;
  color: #1a0dab;
}
.article .date {
  font-size: 12px;
  line-height: 1.3333333333333333em;
  margin-top: 20px;
  color: #70757a;
}
