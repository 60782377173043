/* Forms */
label {
  display: block;
}
.form-container {
  margin-top: 35px;
}
.form-container > *:not(:first-child) {
  margin-top: 25px;
}
form input:not(:first-child),
form select:not(:first-child),
form textarea:not(:first-child) {
  margin-top: 24px;
}
.form-container label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}

.MuiInput-root input {
  height: auto !important;
}

.MuiFormControl-root {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):not(.MuiInputBase-input),
textarea {
  height: 40px;
  border: 1px solid #dfe3eb;
  -webkit-transition: background-color 0.15s ease-in-out 0s;
  transition: background-color 0.15s ease-in-out 0s;
  background-color: #ffffff;
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  font-family: "IBM Plex Sans", sans-serif;
  transition: ease-in-out all 0.3s;
  width: 100%;
}
textarea {
  height: 100px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

input[type="file"] {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #ffffff;
}

.avatar-upload-container {
  position: relative;
}
.avatar-upload::-webkit-file-upload-button {
  visibility: hidden;
}
.avatar-upload {
  font-size: 0;
  width: 118px;
}
.avatar-upload::before {
  content: "Browse files";
  width: auto;
  display: inline-block;
  border: 1px solid #c47335;
  color: #c47335;
  border-radius: 3px;
  padding: 7px 14px;
  outline: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  transition: ease-in-out all 0.2s;
}
.avatar-upload:hover::before {
  background-color: #c47335;
  color: #ffffff;
}
.avatar-upload:active::before {
  /*background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);*/
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):focus {
  background-color: #fff !important;
  width: 100% !important;
}
select {
  font-family: "IBM Plex Sans", sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  -webkit-transition: background-color 0.15s ease-in-out 0s;
  transition: background-color 0.15s ease-in-out 0s;
  border: 1px solid #dfe3eb;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 24px 8px 12px;
  width: 100%;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: calc(100% - 15px) 50%;
  /*background-image: url(../../assets/down-arrow.png);*/
  background-image: url("../../assets/down-triangle@2x.png");
}
input[type="submit"] {
  background-color: #3faebf;
  color: #ffffff;
}
input[type="submit"],
.btn {
  padding: 16px 35px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  transition: ease-in-out all 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
}
.btn.x-small {
  font-size: 10px;
  padding: 4px 10px;
  min-width: 65px;
  text-align: center;
}
.btn.small {
  font-size: 11px;
  padding: 7px 14px;
}
.btn.medium {
  padding: 10px 25px;
  font-size: 14px;
}
input[type="submit"]:hover,
.btn:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 2px 22px rgba(0, 0, 0, 0.04),
    0px 10px 17px rgba(0, 0, 0, 0.1);
  /*transform: translateY(-2px);*/
}

.custom-select-multi {
  list-style: none;
  padding: 14px;
  margin: 0;
  background-color: #f9f9f9;
  height: 192px;
  overflow-y: scroll;
}
.custom-select-multi li {
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
}
.custom-select-multi li:hover,
.custom-select-multi li.selected {
  background-color: rgba(63, 174, 191, 0.1);
  border-color: transparent;
}

.custom-select-dropdown {
  position: relative;
  display: inline-block;
}
.custom-select-dropdown .current-selection > * {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.custom-select-dropdown .current-selection h1 {
  margin: 0 10px 0 0;
}
.custom-select-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  background: #ffffff;
  border: 1px solid #eff0f0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  position: absolute;
  padding: 12px 16px;
  left: 0;
  top: 50px;
  z-index: 2;
  min-width: 128px;
}
.custom-select-dropdown li {
  font-size: 15px;
  padding: 10px;
}
.custom-select-dropdown li:hover {
  background-color: #3faebf;
}
.custom-select-dropdown li a {
  color: #000000;
}
.custom-select-dropdown li:hover a {
  color: #ffffff;
}

.schema-form .flex-container > *:not(:last-child) {
  margin-right: 15px !important;
}
.schema-form .description {
  font-size: 12px;
}
.schema-form .fields-container {
  margin-top: 10px;
}
.schema-form label {
  font-size: 12px;
}
.schema-form label > * {
  margin-bottom: 0;
  margin-top: 0;
}

.schema-form h5 {
  text-align: center;
}

.schema-form .form-group:first-child {
  border-top: none;
  padding-top: 0;
}
.schema-form .form-group {
  border-top: 1px solid #eaeaea;
  padding: 20px 0;
}

/*
* Dropdown select with search
*/

select.dropdown-select {
  /*display: none !important;*/
}

.dropdown-select {
  position: relative;
  font-family: "IBM Plex Sans", sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  transition: background-color 0.15s ease-in-out 0s;
  border: 1px solid #dfe3eb;
  font-size: 14px;
  border-radius: 4px;

  width: 100%;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: calc(100% - 15px) 50%;
  /* background-image: url(../../assets/down-arrow.png); */
}

.dropdown-select:focus {
  background-color: #fff;
}

.dropdown-select:hover {
  background-color: #fff;
}

.dropdown-select:active,
.dropdown-select.open {
  background-color: #fff !important;
  border-color: #bbb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05) inset;
}

.dropdown-select:after {
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  -webkit-transform: origin(50% 20%);
  transform: origin(50% 20%);
  transition: all 0.125s ease-in-out;
  content: "";
  display: block;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;

  background-image: url("../../assets/down-triangle@2x.png");
}
.dropdown-select .current {
  padding: 10px 24px 8px 12px;
}
.dropdown-select.open:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropdown-select.open .list {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}

.dropdown-select.open .option {
  cursor: pointer;
}

.dropdown-select.wide {
  width: 100%;
}
.dropdown-select.half {
  width: 50%;
}

.dropdown-select.wide .list,
.dropdown-select.half .list {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-select .list {
  width: 100%;
  box-sizing: border-box;
  transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 6px;
  margin-top: 4px;
  padding: 3px 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  max-height: 250px;
  overflow: auto;
  border: 1px solid #ddd;
}

.dropdown-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.dropdown-select .dd-search {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
}

.dropdown-select .dd-searchbox {
  width: 90%;
  padding: 0.5rem;
  border: 1px solid #999;
  border-color: #999;
  border-radius: 4px;
  outline: none;
}
.dropdown-select .dd-searchbox:focus {
  border-color: #12cbc4;
}

.dropdown-select .list ul {
  padding: 0;
}

.dropdown-select .option {
  cursor: default;
  font-weight: 400;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
  list-style: none;
}

.dropdown-select .option:hover,
.dropdown-select .option:focus {
  background-color: #f6f6f6 !important;
}
.dropdown-select .option.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.dropdown-select .option.selected {
  font-weight: 600;
  color: #12cbc4;
}

.dropdown-select .option.selected:focus {
  background: #f6f6f6;
}

.dropdown-select a {
  color: #aaa;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.dropdown-select a:hover {
  color: #666;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3faebf !important;
}
