.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loader .bar {
  width: 10px;
  height: 5px;
  background: #10c15c;
  margin: 2px;
  animation: bar 1s infinite linear;
}
.loader .bar:nth-child(1) {
  animation-delay: 0s;
}
.loader .bar:nth-child(2) {
  animation-delay: 0.25s;
}
.loader .bar:nth-child(3) {
  animation-delay: 0.5s;
}
@keyframes bar {
  0% {
    transform: scaleY(1) scaleX(0.5);
  }
  50% {
    transform: scaleY(10) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(0.5);
  }
}

/*
.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 25px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #ffac81;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #ff928b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
*/
