#profile .section:not(:first-child) {
  border-top: 1px solid #efefef;
  padding-top: 35px;
}
#profile .section {
  padding-bottom: 35px;
}
#profile .section h3 {
  margin: 0;
}
#profile .form-container {
  max-width: 440px;
}
#profile .section-title {
  font-size: 20px;
  letter-spacing: -0.17px;
  margin-bottom: 20px;
}

#update-avatar .avatar {
  background-color: #f5f8fa;
  border: 1px solid #e1e4e6;
  border-radius: 3px;
  display: inline-block;
  height: 88px;
  width: 88px;
  position: relative;
  cursor: pointer;
}
#update-avatar .inner {
  border-radius: 50%;
  overflow: hidden;
}
#update-avatar .avatar img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}
#update-avatar .avatar .add-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  max-width: 24px;
}
#update-avatar .avatar-selection {
  justify-content: flex-start;
  align-items: flex-end;
}
#update-avatar .avatar-upload-container {
  padding-left: 16px;
}
#update-avatar .file-name {
  font-size: 14px;
  color: #858585;
  margin-bottom: 12px;
  font-style: italic;
  /*
  white-space: nowrap;
  width: auto;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}
#update-avatar .file-name .flex-container {
  justify-content: flex-start;
}
#update-avatar .remove-image {
  margin-left: 5px;
  cursor: pointer;
  background-color: #3faebf;
  color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  line-height: 14px;
  font-size: 11px;
  font-style: normal;
}

.crop.modal .modal-content {
  padding: 32px;
}
.crop-window .btn {
  margin-top: 16px;
}
.crop-window .inner {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.crop-window,
.select-file {
  background-color: #f9f9f9;
  padding: 25px;
}
.select-file {
  height: 280px;
  max-height: 280px;
  display: block !important;
  height: 40vh;
}
.ReactCrop {
  height: 232px;
  max-height: 232px;
  height: 40vh;
}
.ReactCrop > div {
  height: 100%;
}
.ReactCrop__image {
  display: block;
  max-width: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: auto;
  max-height: 100%;
  margin: 0 auto;
}
.ReactCrop__crop-selection {
  border-radius: 50%;
}
