/* Login Component */

.other-links {
  margin-top: 25px;
}
.other-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.other-links ul li {
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  font-size: 14px;
}
.other-links ul li:first-child {
  padding-left: 0;
}
.other-links ul li:last-child {
  padding-right: 0;
}
.other-links ul li a {
  text-decoration: underline;
  color: #ffffff;
}

#sign-in.flex-container {
  align-items: stretch;
  height: 100vh;
}
#sign-in .left {
  width: 55%;
  padding: 10vh;
}
#sign-in .right {
  width: 640px;
  width: 45%;
  position: relative;
}
#sign-in .left .other-links.fixed-bottom {
  bottom: 60px;
  left: 80px;
  position: absolute;
}
#sign-in .left a {
  color: #ffffff;
}
#sign-in .left .other-links ul li:not(:last-child) {
  border-right: 1px solid #ffffff;
}

#sign-in .left .logo {
  height: 10vh;
  margin: 0 auto;
}
#sign-in .left .image {
  margin: 5vh auto;
  height: 50vh;
}
#sign-in .left .logo img,
#sign-in .left .image img {
  max-height: 100%;
}
#sign-in .left .tagline {
  font-size: 3vh;
  font-weight: 500;
  display: block;
}

#sign-in .right .other-links ul li a {
  color: #ffffff;
}
#sign-in .right .other-links.fixed-bottom {
  color: #ffffff;
  padding: 0 12vh;
  position: absolute;
  bottom: 60px;
  left: 0;
}

#signinForm {
  padding: 5vh 12vh;
}
#signinForm .section-title {
  font-size: 30px;
  font-size: 4vh;
  line-height: 1.3em;
  margin: 0 0 32px;
  letter-spacing: 0;
  text-transform: none;
  color: #ffffff;
  border: none;
  padding: 0;
}
#signinForm .form-container {
  margin-top: 0;
}
#signinForm .logo {
  width: 75px;
  max-height: 75px;
  margin-bottom: 25px;
  display: block;
}
#signinForm
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
#signinForm textarea {
  background-color: #11344e !important;
  height: auto;
  border-radius: 3px;
  border: none;
  -webkit-transition: background-color 0.15s ease-in-out 0s;
  transition: background-color 0.15s ease-in-out 0s;
  transition: ease-in-out all 0.3s;
  font-size: 16px;
  width: 100%;
  color: #ffffff !important;
  box-sizing: border-box;
}
#signinForm .MuiFormLabel-root {
  color: #ffffff;
}

#signinForm
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
}

#signinForm
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}

#signinForm
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder {
  color: #ffffff !important;
}

#signinForm input:-webkit-autofill,
#signinForm input:-webkit-autofill:hover,
#signinForm input:-webkit-autofill:focus,
#signinForm textarea:-webkit-autofill,
#signinForm textarea:-webkit-autofill:hover,
#signinForm textarea:-webkit-autofill:focus,
#signinForm select:-webkit-autofill,
#signinForm select:-webkit-autofill:hover,
#signinForm select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #11344e inset !important;
  -webkit-text-fill-color: #ffffff !important;
  border: none !important;
}

#signinForm input[type="submit"] {
  height: 7vh;
  max-height: 50px;
  width: 100%;
}
#signinForm input[type="submit"]:hover {
  background-color: var(--teal-dark);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
}

.social-signin > button {
  justify-content: center;
  padding: 5px 12px !important;
  border-radius: 30px !important;
  transition: ease-in-out 0.3s all;
}
.social-signin svg {
  display: block;
}
.social-signin > button > * {
  color: #000000;
  font-family: "IBM Plex Sans", sans-serif;
}

/*
* Google Sign In
*/
#googleSignIn:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2) !important;
  transform: translateY(-7px);
}

@media screen and (max-width: 768px) {
  #sign-in.flex-container {
    display: block;
  }
  #sign-in .left,
  #sign-in .right {
    width: 100%;
    padding: 5vh;
  }
  #sign-in .left .image {
    margin: 5vh auto;
    height: 30vh;
  }
  #sign-in .left .logo {
    height: 6vh;
  }
  #signinForm {
    transform: none;
    padding: 5vh;
  }
  #sign-in .right .other-links.fixed-bottom {
    padding: 0 5vh;
    bottom: 0;
    position: relative;
    left: 0;
  }
}
