.table-wrap {
  overflow: hidden;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.table-container {
  padding: 0;
  margin: 0;
  border: 1px solid #efefef;
}
.table-header {
  padding: 0 20px;
  background-color: #f9fbfc;
  z-index: 2;
  border: 1px solid #efefef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 0;
}
#accounts .table-item:first-child {
  border-top: none;
}

#accounts .table-item > *:first-child,
#accounts .table-header > *:first-child {
  width: 40%;
  justify-content: flex-start;
  min-width: 300px;
}

#accounts .table-item > *:nth-child(2),
#accounts .table-header > *:nth-child(2) {
  width: 20%;
}
#accounts .table-item > *:nth-child(3),
#accounts .table-header > *:nth-child(3) {
  width: 10%;
}
#accounts .table-item > *:nth-child(4),
#accounts .table-header > *:nth-child(4) {
  width: 10%;
  text-align: center;
}
#accounts .table-item > *:nth-child(5),
#accounts .table-header > *:nth-child(5) {
  width: 30%;
  text-align: right;
}

#accounts h4 {
  margin: 0;
}
#accounts .table-item {
  font-size: 14px;
  padding: 12px 20px;
}
#accounts .table-item .btn-container {
  right: 20px;
}
#accounts .table-item .btn {
  font-size: 12px;
}
#accounts .btn-container.flex-container {
  justify-content: flex-end;
}
#accounts .btn-container.flex-container > * {
  margin: 0 5px;
}
#accounts .btn-container.flex-container > *:first-child {
  margin-left: 0;
}
#accounts .btn-container.flex-container > *:last-child {
  margin-right: 0;
}

#accounts .options {
  display: none;
  background: #ffffff;
  border: 1px solid #eff0f0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  position: absolute;
  padding: 12px 16px;
  right: 15px;
  top: 50px;
  z-index: 2;
  min-width: 128px;
}
#accounts .options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#accounts .options li {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  #accounts .table-item {
    font-size: 12px;
  }
}
