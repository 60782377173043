#register {
  min-height: 100vh;
}
#register .container {
  max-width: 500px;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 8vh 20px;
}
#register .logo {
  margin-bottom: 15px;
}
#register .logo img {
  max-width: 275px;
}
#register .MuiFormLabel-root {
  color: #ffffff;
}
#register .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.87);
}

#register input:-webkit-autofill,
#register input:-webkit-autofill:hover,
#register input:-webkit-autofill:focus,
#register textarea:-webkit-autofill,
#register textarea:-webkit-autofill:hover,
#register textarea:-webkit-autofill:focus,
#register select:-webkit-autofill,
#register select:-webkit-autofill:hover,
#register select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #11344e inset !important;
  -webkit-text-fill-color: #ffffff !important;
  border: none !important;
}

#register input[type="checkbox"] {
  margin-right: 10px;
}

#register input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
#register textarea {
  background-color: #11344e !important;
  height: auto;
  /*height: 7vh;
  max-height: 48px;
  */
  border-radius: 4px;
  border: none;
  -webkit-transition: background-color 0.15s ease-in-out 0s;
  transition: background-color 0.15s ease-in-out 0s;
  transition: ease-in-out all 0.3s;
  /*padding: 14px 16px;*/
  font-size: 16px;
  width: 100%;
  color: #ffffff !important;
  box-sizing: border-box;
}
#register
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
}

#register
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}

#register
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"])::placeholder {
  color: #ffffff !important;
}
#register input[type="submit"] {
  width: 100%;
  height: 7vh;
  max-height: 48px;
}
#register input[type="submit"]:hover {
  background-color: var(--teal-dark);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
}
#register input.col-2:first-child {
  margin-right: 10px;
}
#register input.col-2:last-child {
  margin-left: 10px;
}
