/* Input Component */
#input {
  padding: 100px 0;
  min-height: 100vh;
  position: relative;
  height: 100%;
}
#input .container {
  max-width: 500px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
}
#input .form-container {
  margin-top: 50px;
}
#input .form-container > * {
  margin-top: 25px;
}
