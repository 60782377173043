span[data-period] {
  font-size: 13px;
  font-weight: 500;
  color: rgba(17, 51, 83, 0.3);
}
span[data-period].active {
  color: #0b263a;
}
span[data-period]:hover {
  cursor: pointer;
  color: rgb(5, 15, 25);
}
.analytics canvas {
  /*max-height: 50vh !important;*/
  height: 40vh !important;
}
.analytics .MuiCardContent-root {
  padding: 24px;
}
.view-summary .MuiBox-root {
  border-color: #eaeaea;
}
