.modal:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
}
.modal-content {
  position: fixed;
  z-index: 6;
  margin: 0 auto;
  max-width: 720px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: inherit;
  overflow: hidden;
  text-align: center;
  padding: 88px 72px;
  background-color: #ffffff;
  border: 1px solid #eff0f0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.modal-content .close {
  padding: 20px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.modal-content .progress {
  margin-top: 25px;
  overflow: hidden;
}
.modal-content .icon {
  max-width: 40px;
  margin: 0 auto 25px;
}
.modal-content .title {
  font-size: 26px;
  font-weight: 600;
}
.modal-content .description {
  font-size: 14px;
  margin: 23px auto 0;
  line-height: 1.5em;
  max-width: 500px;
}
.modal-content .btn {
  margin-top: 32px;
}
.modal-content .buttons {
  margin-top: 25px;
}
.modal-content .buttons > * {
  margin: 0 10px;
  min-width: 149px;
}

.modal-content label {
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
}

.select-a-domain .modal-content .description {
  max-width: none;
}
.select-a-domain .modal-content {
  padding: 32px;
}

@media screen and (max-width: 500px) {
  .modal-content .description {
    font-size: 12px;
  }
  .modal-content .close {
    right: 0;
    top: 0;
  }
  .select-a-domain .modal-content {
    padding: 25px;
  }

  .modal-content {
    max-width: 80%;
  }
}
