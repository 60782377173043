/* Domains Component */
#addDomains {
  margin-top: 20px;
}

.domain-item .master-username,
.domain-item .master-plan {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}
.domain-item .master-username {
  margin-top: 10px;
}

#sites .domain-item {
  transition: background-color 150ms ease-in-out 0s;
  font-size: 16px;
  color: #000000;
  border-top: 1px solid #efefef;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  justify-content: flex-start;
}
#sites .domain-item:first-child {
  border-top: transparent;
}

#sites .domain-item:hover {
  background-color: rgba(63, 174, 191, 0.1);
  border-color: transparent;
}
#sites .domain-item:hover + .domain-item {
  border-top: 1px solid transparent;
}

#sites .domain-item h4 {
  margin: 0;
}
#sites .domain-item .domain-info {
  width: 90%;
}
#sites .domain-item .btn-container {
  width: 10%;
  text-align: right;
  position: absolute;
  right: 20px;
}

#sites .domain-item .domain-title {
  margin: 0;
  font-weight: 500;
}
#sites .btn-container.flex-container {
  justify-content: flex-end;
}
#sites .btn-container.flex-container > * {
  margin: 0 5px;
}
#sites .btn-container.flex-container > *:first-child {
  margin-left: 0;
}
#sites .btn-container.flex-container > *:last-child {
  margin-right: 0;
}

#sites .options {
  display: none;
  background: #ffffff;
  border: 1px solid #eff0f0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  position: absolute;
  padding: 12px 16px;
  right: 15px;
  top: 50px;
  z-index: 2;
  min-width: 128px;
}
#sites .options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#sites .options li {
  cursor: pointer;
}
#sites .available-domains {
  margin-top: 16px;
}
.domain-item .portal-id {
  font-weight: 500;
}

.select-a-domain .modal-content {
  text-align: left;
  max-width: 720px;
}

.no-domains-found {
  font-size: 14px;
  padding: 0 20px;
}
.no-domains-found #guideMe {
  margin-left: 15px;
}

/* Table settings */
#sites .table-header {
  padding: 0 20px;
  justify-content: flex-start;
}
#sites .domain-item > *:first-child,
#sites .table-header > *:first-child {
  width: 65%;
  justify-content: flex-start;
}
#sites .domain-item > *:nth-child(2),
#sites .table-header > *:nth-child(2) {
  width: 35%;
}

#sites .domain-item .hover-menu {
  /*opacity: 0;
  visibility: hidden;*/
  transition: ease-in-out all 0.3s;
  position: absolute;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  height: 100%;
  /*width: auto;*/
  right: 25px;
  display: flex;
  align-items: center;
}
#sites .domain-item:hover .hover-menu {
  opacity: 1;
  visibility: visible;
}
#sites .domain-item .hover-menu > * {
  margin: 0 5px;
}

.role {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

/*
* Available domain selector
*/
.available-domains {
  padding: 0;
}
.available-domains .pill-btn {
  background-color: #4985e3;
  color: #ffffff;
}
.available-domains .tags {
  margin: 0 -10px;
}
.available-domains .tags .pill-btn {
  margin: 10px 10px 0;
}
.available-domains .domain:not(:first-child) {
  border-top: 1px solid #eaeaea;
}
.available-domains .domain {
  padding: 15px;
}

.available-domains .domain .name {
  font-weight: 500;
}

/*
* Scroll down animation
*/

.scroll-downs {
  margin: auto;
  width: 34px;
  height: 55px;
  right: 50px;
  position: absolute;
  top: 50%;
}
.mousey {
  width: 3px;
  padding: 8px 12px;
  height: 30px;
  border: 2px solid #000;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  background-color: #ffffff;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #000000;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
