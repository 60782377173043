/* Info Bar */
#info-bar {
  padding: 35px 20px;
  text-align: right;
  font-size: 14px;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
}
#info-bar img {
  margin-right: 10px;
  width: 20px;
}
