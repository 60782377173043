#site-header {
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  height: 80px;
  padding: 12px 32px;
  margin-bottom: 42px;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 80px);
  -webkit-transition: ease-in-out padding 0.2s;
  -moz-transition: ease-in-out padding 0.2s;
  -o-transition: ease-in-out padding 0.2s;
  transition: ease-in-out padding 0.2s;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#main-content.expanded #site-header {
  padding-left: 210px;
}

#site-header h1 {
  font-size: 24px;
  letter-spacing: -0.2px;
  font-weight: bold;
  margin: 0;
}
#site-header .current-selection h1 {
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#header {
  position: absolute;
  text-align: right;
  width: 100%;
  top: 0;
  padding: 10px 0;
}
#header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#header nav a {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
}
#header nav li {
  display: inline-block;
  vertical-align: top;
}
#header nav li:not(:last-child) {
  margin-right: 15px;
}
#header nav li:nth-last-child(2) > a,
#header nav li:last-child > a {
  padding: 12px 35px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out all 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
}
#header nav li:nth-last-child(2) > a {
  border: 1px solid #ffffff;
}
#header nav li:last-child > a {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000000;
}
#header nav li:nth-last-child(2) > a:hover,
#header nav li:last-child > a:hover {
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 2px 22px rgba(0, 0, 0, 0.04),
    0px 10px 17px rgba(0, 0, 0, 0.1);
}

#manage-subscription {
  /*
  position: absolute;
  top: 20px;
  right: 30px;
  */
}

.site-header-container {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 80px);
  transition: ease-in-out width 0.2s;
  z-index: 5;
}
.site-header-container #site-header {
  position: relative;
  width: 100%;
  margin-bottom: 0;
}
.site-header-container .top-bar {
  transition: ease-in-out width 0.2s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 32px;
  z-index: 2;
  background-color: #f2f2f2;
}

/*
* Domain Selector
*/
#site-header .domain-selector .custom-select-dropdown.opened .items {
  display: block;
}
#site-header .domain-selector .custom-select-dropdown .items {
  width: 100%;
}
#site-header .domain-selector .custom-select-dropdown .items a {
  display: block;
}

/*
* Account Dropdown
*/

.account-menu .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.account-menu .avatar img {
  width: 100%;
}
.account-menu .dropdown {
  background-color: #ffffff;
  display: none;
  position: absolute;
  padding: 35px 25px 0;
  border-radius: 6px;
  right: 35px;
  top: 90px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  z-index: 12;
  min-width: 300px;
}
.account-menu.opened > .dropdown {
  display: block;
}
.account-menu .top {
  text-align: center;
}
.account-menu .top .avatar {
  margin: 0 auto 20px;
  width: 100px;
  height: 100px;
}

.account-menu .name {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
}
.account-menu .email {
  color: #666666;
}
.account-menu .bottom {
  margin-top: 25px;
}
.account-menu .bottom > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.account-menu .bottom > ul > li {
  border-top: 1px solid #eaeaea;
}
.account-menu .bottom > ul a {
  display: block;
  padding: 15px 25px;
  color: #000000;
  width: calc(100% + 50px);
  position: relative;
  left: -25px;
}
.account-menu .bottom > ul a:hover {
  background-color: rgba(63, 174, 191, 0.1);
  /*border-left: 3px solid #3faebf;*/
}
.account-menu .bottom > ul > li:hover,
.account-menu .bottom > ul > li:hover + li {
  border-color: transparent;
}
.account-menu .bottom > ul > li:last-child a {
  color: #ff0000;
}

@media screen and (max-width: 768px) {
  #site-header h1 {
    font-size: 22px;
  }
  #manage-subscription {
    display: none;
  }
}

/*
* Support PIN
*/

.support-pin h3 {
  margin: 0;
  padding-right: 5px;
}
.support-pin #support-pin {
  font-size: 14px;
  margin-right: 5px;
}
.support-pin .icon {
  width: 15px;
  cursor: pointer;
}
