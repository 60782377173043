/* Sidebar */
#sidebar {
  width: 80px;
  /*padding: 75px 28px;*/
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  font-size: 16px;
  -webkit-transition: ease-in-out width 0.2s;
  -moz-transition: ease-in-out width 0.2s;
  -o-transition: ease-in-out width 0.2s;
  transition: ease-in-out width 0.2s;
  z-index: 6;
}
#sidebar:hover {
  z-index: 6;
}
#sidebar.expanded {
  width: 250px;
}
#sidebar.expanded [data-id="tooltip"] {
  display: none !important;
}

#sidebar .name {
  /*display: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 70px;
  width: 200px;
  */
}
#sidebar .name {
  white-space: nowrap;
}
#sidebar:not(.expanded) .name {
  display: none;
}
#sidebar.expanded .name {
  display: block;
}
#sidebar .name .more {
  z-index: 10;
  margin-left: 10px;
}

#sidebar .menu-depth-1 {
  /*height: 67px;*/
  overflow: hidden;
}
#sidebar .menu-depth-1.larger-icon {
  height: auto;
}
#sidebar .menu-depth-1.larger-icon .menu-item {
  padding: 20px 0;
}
#sidebar .sub-menu {
  display: none;
  padding: 0 0 0 70px;
}
#sidebar .sub-menu.expanded {
  display: block;
}
#sidebar .sub-menu li {
  margin-top: 22px;
}
#sidebar .sub-menu a {
  color: #3faebf;
  display: block;
}
#sidebar .sub-menu a:hover {
  color: #ffffff;
}

#sidebar a {
  color: #ffffff;
}
#sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#sidebar .menu-item {
  cursor: pointer;
  -webkit-transition: ease-in-out opacity 0.2s;
  -moz-transition: ease-in-out opacity 0.2s;
  -o-transition: ease-in-out opacity 0.2s;
  transition: ease-in-out opacity 0.2s;
  position: relative;
  display: block;
  padding: 14px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
#sidebar.expanded .menu-item {
  justify-content: flex-start;
}
#sidebar a.menu-item.active,
#sidebar a.menu-item:hover {
  background-color: #11344e;
}
#sidebar a.menu-item.active:before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: #3faebf;
  position: absolute;
  left: 0;
  top: 0;
}
#sidebar .menu-item > .icon img.active {
  display: none;
}
#sidebar .menu-item.active > .icon img.active {
  display: block;
}
#sidebar .menu-item.active > .icon img.default {
  display: none;
}

#sidebar .logo-box,
#sidebar .account-box {
  padding: 20px 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
#sidebar .logo-box {
  justify-content: center !important;
}
#sidebar.expanded .logo {
  margin-right: 10px;
}
#sidebar .account-box .menu-item-inner {
  position: relative;
}
#sidebar .logo,
#sidebar .avatar {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
#sidebar .default-avatar {
  border-radius: 50%;
  background-color: #d431bd;
  height: 40px;
  width: 40px;
  color: #ffffff;
  text-align: center;
}

#sidebar .account-box.expanded .name .more {
  transform: rotate(180deg);
}
#sidebar .account-box:hover .menu-depth-2 {
  display: block;
}

#sidebar .logo-box .name {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
#sidebar .icon {
  width: 20px;
  height: 20px;
  display: block;
}
#sidebar .icon svg {
  max-width: 100%;
}
#sidebar.expanded .icon {
  margin-right: 18px;
  display: inline-block;
}
#sidebar .icon img {
  width: 100%;
  max-height: 20px;
  display: block;
  -webkit-transition: ease-in-out transform 0.2s;
  -moz-transition: ease-in-out transform 0.2s;
  -o-transition: ease-in-out transform 0.2s;
  transition: ease-in-out transform 0.2s;
}
#sidebar.expanded .icon.rotate-on-open img,
#sidebar .menu-item:hover .icon.rotate-on-hover img {
  transform: rotate(180deg);
}
#sidebar .fixed-top {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
}

#sidebar .fixed-bottom {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}

#sidebar .menu-depth-1:hover .menu-depth-2 {
  display: block;
}
#sidebar .menu-depth-2 {
  display: none;
  position: fixed;
  height: 100%;
  left: 80px;
  top: 0;
  width: 280px;
  background-color: #11344e;
  -webkit-transition: ease-in-out left 0.2s;
  -moz-transition: ease-in-out left 0.2s;
  -o-transition: ease-in-out left 0.2s;
  transition: ease-in-out left 0.2s;
}
#sidebar.expanded .menu-depth-2 {
  left: 250px;
}
#sidebar .menu-depth-2 .close {
  font-size: 15px;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 28px 26px;
}
#sidebar .menu-depth-2 .close > * {
  display: inline-block;
  vertical-align: middle;
}
#sidebar .menu-depth-2 .close > *:first-child {
  margin-right: 15px;
}

#sidebar .menu-depth-2 .menu-items {
  padding: 28px;
}
#sidebar .menu-depth-2 .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 29px;
}
#sidebar .menu-depth-2 ul {
  padding-left: 15px;
  position: relative;
}
#sidebar .menu-depth-2 ul:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #1c4d6a;
  top: 0;
  left: 0;
}

#sidebar .menu-depth-2 .menu-items li {
  font-size: 14px;
  letter-spacing: 0.47px;
}
#sidebar .menu-depth-2 .menu-items li:not(:last-child) {
  margin-bottom: 22px;
}
#sidebar .menu-depth-2 .menu-items a {
  color: #3faebf;
  position: relative;
}
#sidebar .menu-depth-2 .menu-items a:hover,
#sidebar .menu-depth-2 .menu-items a.active {
  color: #ffffff;
}
#sidebar .menu-depth-2 .menu-items a.active:before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: #3faebf;
  position: absolute;
  left: -15px;
  top: 0;
}
