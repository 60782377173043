#products .top {
  padding-bottom: 70px;
}
#products .canceled .desc {
  margin-top: 10px;
}
#products select#subscription-filter {
  max-width: 150px;
}
#products .plan:not([data-interval="month"]) {
  display: none;
}
.product-list {
  display: flex;
  /*
  align-items: center;
  justify-content: center;
  */
  flex-wrap: wrap;
  margin: 0 -20px;
}
.product-list .product {
  margin: 0 20px;
  width: calc(calc(100% / 4) - 40px);
  flex: 1;
  position: relative;
}
.product-list .currentPackage:before {
  display: block;
  content: "Current Plan";
  text-align: center;
  background-color: #3faebf;
  color: #ffffff;
  padding: 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.27px;
  position: absolute;
  top: -33px;
  width: calc(100% - 14px);
  left: 0;
}
.product-list .plans {
  height: 100%;
}
.product-list .plan {
  padding: 32px 40px 95px;
  text-align: center;
  border: 1px solid #eaeaea;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.product-list .plan .name {
  display: inline-block;
  border-radius: 25px;
  padding: 12px 18px;
  line-height: 1;
  font-weight: 500;
  font-size: 12px;
  min-width: 118px;
  text-align: center;
  background-color: #11344e;
  color: #ffffff;
}
.product-list .plan .amount {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.27px;
  margin: 17px auto 0;
  color: #3faebf;
}
.product-list .plan .amount-label {
  font-size: 12px;
  color: #595959;
  margin-top: 10px;
}
.product-list .plans .summary {
  border-top: 1px solid #efefef;
  margin-top: 16px;
  padding: 16px 0 0;
  list-style: none;
  font-size: 12px;
}
.product-list .btn {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  width: calc(100% - 80px);
  margin: 0 auto;
  z-index: 2;
}

.subscription-dates {
  font-size: 14px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1140px) {
  .product-list .product {
    margin: 20px;
    width: calc(calc(100% / 2) - 40px);
  }
}

@media screen and (max-width: 500px) {
  .product-list .product {
    margin: 20px;
    width: 100%;
  }
}
