#templates .section-title h3 {
  margin-bottom: 0;
}
.template-table {
  list-style: none;
  padding: 0;
  margin: 0;
}

.template-item:first-child {
  border-top: none;
}
.template-item:last-child {
  border-bottom: none;
}

.template-item {
  transition: background-color 150ms ease-in-out 0s;
  font-size: 16px;
  color: #000000;
  border-top: 1px solid #efefef;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  justify-content: flex-start !important;
}
.template-item:hover {
  background-color: rgba(63, 174, 191, 0.1);
  border-color: transparent;
}
.template-item:hover + .template-item {
  border-top: 1px solid transparent;
}

.template-item .name {
  margin: 0;
  font-weight: 500;
}
.template-item .hover-menu {
  transition: ease-in-out all 0.3s;
  position: absolute;
  height: 100%;
  right: 20px;
  display: flex;
  align-items: center;
}
.template-item:hover .hover-menu {
  opacity: 1;
  visibility: visible;
}
.template-item .hover-menu > * {
  margin: 0 5px;
}
