/* Grid-related CSS */

:root {
  --square-size: 1.27vw;
  --square-gap: 0.3vw;
  --week-width: calc(var(--square-size) + var(--square-gap));
}

#activity-chart ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#activity-chart .months {
  grid-area: months;
  text-align: center;
}
#activity-chart .days {
  grid-area: days;
}
#activity-chart .squares {
  grid-area: squares;
}

#activity-chart .graph {
  display: inline-grid;
  grid-template-areas:
    "empty months"
    "days squares";
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  width: 100%;
}

#activity-chart .months {
  font-size: 1.1vw;
  display: grid;
  grid-template-columns:
    calc(var(--week-width) * 4) /* Jan */
    calc(var(--week-width) * 4) /* Feb */
    calc(var(--week-width) * 4) /* Mar */
    calc(var(--week-width) * 5) /* Apr */
    calc(var(--week-width) * 4) /* May */
    calc(var(--week-width) * 4) /* Jun */
    calc(var(--week-width) * 5) /* Jul */
    calc(var(--week-width) * 4) /* Aug */
    calc(var(--week-width) * 4) /* Sep */
    calc(var(--week-width) * 5) /* Oct */
    calc(var(--week-width) * 4) /* Nov */
    calc(var(--week-width) * 5) /* Dec */;
}

#activity-chart .days,
#activity-chart .squares {
  display: grid;
  font-size: 1.1vw;
  grid-gap: var(--square-gap);
  grid-template-rows: repeat(7, var(--square-size));
}

#activity-chart .squares {
  grid-auto-flow: column;
  grid-auto-columns: var(--square-size);
}

/*
* Tooltip
*/
#activity-chart [data-changes] {
  position: relative;
}
#activity-chart [data-changes]:hover::before {
  visibility: visible;
  opacity: 1;
}
#activity-chart [data-changes]:hover::after {
  visibility: visible;
  opacity: 1;
}
#activity-chart [data-changes]:before {
  content: attr(data-changes);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  background: #222;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 8px 21px;
  margin-left: 11px;
  margin-top: -10px;
  min-width: 75px;
  transition: opacity 0.3s ease-out;
  z-index: 1;
}
#activity-chart [data-changes]::after {
  content: "";
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: 4px;
  left: 50%;
  margin-left: -8px;
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
  transform: rotate(90deg);
  transition: opacity 0.3s ease-out;
}

/* Other styling */
.activity-component {
  margin-top: 50px;
}
#activity-chart {
  margin-top: 20px;
}
#activity-chart .graph {
  border: 1px #efefef solid;
  overflow-x: auto;
  padding: 1.3vw;
  border-radius: 8px;
}

#activity-chart .days li:nth-child(odd) {
  visibility: hidden;
}

#activity-chart .squares li {
  background-color: #ebedf0;
}

#activity-chart .squares li[data-level="1"] {
  background-color: #c6e48b;
}

#activity-chart .squares li[data-level="2"] {
  background-color: #7bc96f;
}

#activity-chart .squares li[data-level="3"] {
  background-color: #196127;
}
