@import "./components/header.css";
@import "./components/forms.css";
@import "./components/login.css";
@import "./components/modal.css";
@import "./components/bubbles.css";
@import "./components/loader.css";
@import "./components/input.css";
@import "./components/results.css";
@import "./components/pages.css";
@import "./components/sidebar.css";
@import "./components/info-bar.css";
@import "./components/domains.css";
@import "./components/buttons.css";
@import "./components/animations.css";
@import "./components/dashboard.css";
@import "./components/profile.css";
@import "./components/manage-account.css";
@import "./components/admin.css";
@import "./components/table.css";
@import "./components/activity-chart.css";
@import "./components/verify.css";
@import "./components/account-dashboard.css";
@import "./components/users.css";
@import "./components/templates.css";
@import "./components/checkmark.css";
@import "./components/nav.css";

@import "./stripe/store.css";
@import "./components/plans.css";
@import "./components/payment-form.css";
@import "./components/recover.css";

/* SERP */
@import "./serp/google.css";

/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

* {
  outline: none;
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.2em;
  transition: ease-in-out margin 0.3s;
}

.overlay {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 500px) {
  body {
    font-size: 14px;
  }
}
body .light-theme {
  color: #000;
}
body .dark-theme {
  color: #ffffff;
}

#root,
#root > *,
div#app,
div#app > *,
div#app > * > * {
  height: 100%;
}

.CodeMirror {
  font-size: 12px !important;
}

#main-content {
  min-height: 100%;
  width: calc(100% - 80px);
  margin-left: 80px;
  -webkit-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  -moz-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  -o-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  position: relative;
}
#main-content.expanded {
  width: calc(100% - 250px);
  margin-left: 250px;
}
#main-content.no-sidebar {
  margin: 0;
  width: 100%;
}
.content-window {
  padding: 122px 40px 40px;
}

a,
.link {
  color: #3faebf;
  font-weight: 500;
  transition: ease-in-out all 0.3s;
  text-decoration: none;
}

.link {
  font-weight: 600;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}
.ta-right {
  text-align: right;
}
.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.justify-center {
  justify-content: center;
}

.vh-100 {
  height: 100vh;
}
.container {
  padding: 0 20px;
  position: relative;
  z-index: 2;
}
.mw-440 {
  max-width: 440px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}

.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.strikethrough {
  text-decoration: line-through;
}

.capitalize {
  text-transform: capitalize;
}

.success-label {
  color: #1fad9d;
  background-color: rgba(31, 173, 57, 0.1);
  border-color: #1fad9d;
  padding: 15px 20px;
  margin: 0 auto 10px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.error-label {
  color: #86181d;
  background-color: #ffdce0;
  border-color: rgba(27, 31, 35, 0.15);
  padding: 15px 20px;
  margin: 0 auto 10px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.error-label-2 {
  font-size: 12px;
  color: #d64b33;
}
.error-label-2 a {
  color: #d64b33;
  font-weight: bold;
  text-decoration: underline;
}

.btn.disabled {
  opacity: 0.4;
}
.disabled > img,
.disabled > span,
.disabled.pill-button > .MuiIconButton-label > svg,
.disabled.pill-button > .MuiIconButton-label > .text {
  opacity: 0.35;
}
.disabled.pill-button > span {
  opacity: 1;
}
.disabled:hover {
  cursor: not-allowed;
}

.MuiSvgIcon-root.small {
  width: 15px;
  height: 15px;
}

/*
* Colors
*/
:root {
  --teal: #3faebf;
  --teal-dark: #328b98;
  --midnight-blue: #081e2e;
}

/* Font Color */
.c-white {
  color: #ffffff;
}
.c-black {
  color: #000;
}
.c-midnight-blue {
  color: #0b263a;
}
.c-orange {
  color: #c47335;
}
.c-green {
  color: rgb(0, 189, 165);
}
.c-red {
  color: #d64b33;
}
.c-light-gray {
  color: rgb(203, 214, 226);
}
.c-gray {
  color: #d3d5d8;
}
.c-teal {
  color: #3faebf;
}
.c-yellow {
  color: #f7ac1f;
}

.h-c-white:hover {
  color: #ffffff;
}
.h-c-midnightblue:hover {
  color: #0b263a;
}

/* Background color */
.bg-white {
  background-color: #fff;
}
.bg-white-opaque {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-stone {
  background-color: #7e7f73;
}
.bg-light-orange {
  background-color: #ffc166;
}
.bg-orange {
  background-color: rgb(214, 130, 49);
}
.bg-green {
  background-color: #1fad9d;
}
.bg-peach {
  background-color: #ff9966;
}
.bg-red {
  background-color: #d64b33;
}
.bg-light-gray {
  background-color: #f8f9fb;
}
.bg-midnightblue {
  background-color: #0b263a;
}
.bg-midnightblue-2 {
  background-color: #081e2e;
}
.bg-lightseagreen,
.bg-teal {
  background-color: #3faebf;
}
.bg-magenta {
  background-color: #d431bd;
}

.h-bg-white:hover {
  background-color: #ffffff;
}
.h-bg-orange:hover {
  background-color: rgb(214, 130, 49);
}
.h-bg-dark-orange:hover {
  background-color: #c0752c;
}

/* Border Color */
.b-orange {
  border-color: rgb(214, 130, 49);
}
.b-white {
  border-color: #ffffff;
}
.b-lightseagreen,
.b-teal {
  border-color: #3faebf;
}

img {
  max-width: 100%;
  height: auto;
}
table {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
table th {
  text-align: center;
  padding: 15px;
}
table td {
  padding: 15px;
}
table td img {
  max-width: 100%;
  margin: 0 auto;
}

.dropzone {
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.hidden {
  display: none;
}

.__react_component_tooltip {
  max-width: 300px;
  text-align: left;
}

.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mr-0 {
  margin-right: 0 !important;
}

.padding-top {
  padding-top: 100px;
}
.padding-bottom {
  padding-bottom: 100px;
}

/* Columns */
.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}
.col-3 {
  width: calc(100% / 3);
}

@media screen and (max-width: 768px) {
  .t-col-1 {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .m-col-1 {
    width: 100%;
  }
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  line-height: 1.2em;
  letter-spacing: -3.5px;
  font-weight: 500;
}
h1,
.h1,
.MuiTypography-root.h2 {
  font-size: 54px;
}
h2,
.h2,
.MuiTypography-root.h2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.13px;
}
h3,
.h3,
.MuiTypography-root.h3 {
  font-family: "IBM Plex Sans", sans-serif;
  color: #858585;
  font-weight: 600;
  font-size: 14px;
}
.MuiTypography-h5 {
  font-weight: 500 !important;
}

.MuiTypography-root,
.MuiTableCell-root {
  font-family: "IBM Plex Sans", sans-serif !important;
}

.MuiTypography-root.MuiTypography-h6 {
  font-size: 15px;
}

.MuiTypography-root.MuiTypography-body1 {
  font-size: 14px;
}

.MuiTableCell-root {
  text-transform: capitalize;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}
.section-title {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 30px;
}
.section-title h2 {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  h2,
  .h2,
  .MuiTypography-root.h2 {
    font-size: 20px;
  }
  h4,
  .h4,
  .MuiTypography-root.h4 {
    font-size: 14px;
  }
}

.d-flex {
  display: flex;
}
.d-flex.justify-right {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.flex-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .flex-container.t-no-flex {
    display: block;
  }
}

.full-height {
  padding: 100px 0;
  min-height: 100vh;
  position: relative;
  height: 100%;
}

#sign-in .container,
.full-height .container {
  max-width: 500px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
}

.accordion-toggle {
  position: relative;
  cursor: pointer;
}
/*
.accordion-toggle::after {
  content: "";
  background-image: url("../assets/down-triangle@2x.png");
  width: 12px;
  display: block;
  height: 7px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: ease-in-out transform 0.3s;
}
*/

.accordion-toggle.opened::after {
  transform: rotate(180deg);
}
.accordion.opened .accordion-content {
  display: block;
}
.accordion-content {
  display: none;
}

.retreiving-data {
  overflow: hidden;
}
.progress {
  width: 100%;
  height: 5px;
  border-radius: 12px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.progress-label {
  margin-top: 8px;
  font-size: 12px;
}

/*  Infinite Loading Progress Bar */
.progress__item--inf-prog {
  width: 50%;
  height: 15px;
  position: relative;
  background-color: #3faebf;
  animation: progress 5s infinite;
  -webkit-animation: progress 1.5s ease infinite;
}

@keyframes progress {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 25%;
  }
  50% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 100%;
  }
}

@-webkit-keyframes progress {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 25%;
  }
  50% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 100%;
  }
}

.rotate-on-hover {
  -webkit-transition: ease-in-out transform 0.2s;
  -moz-transition: ease-in-out transform 0.2s;
  -o-transition: ease-in-out transform 0.2s;
  transition: ease-in-out transform 0.2s;
}
.rotate-on-hover:hover {
  transform: rotate(180deg);
}

.overlap-below {
  position: relative;
  bottom: -30px;
  z-index: 2;
  left: -30px;
  margin-top: -30px !important;
}

.rnc__notification-item {
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}
.rnc__notification-message {
  color: #000000;
}
.rnc__notification-content {
  padding: 12px 20px;
}
.rnc__notification-close-mark {
  top: 50%;
  transform: translateY(-50%);
}
