/* Payment Form */
.ErrorMessage {
  color: #000000;
}
#payment-form {
  text-align: center;
}
#payment-form .form-row {
  max-width: 500px;
  margin: 25px auto;
}
#payment-form .form-row label {
  text-align: left;
  margin-bottom: 5px;
  font-size: 14px;
  display: block;
}
#payment-form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
#payment-form textarea {
  margin-top: 0;
  font-size: 16px;
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

#payment-form #promo-section {
  position: relative;
}
#payment-form #promo-section .validation {
  position: absolute;
  bottom: 8px;
  right: 10px;
}

.payments .pricing {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.27px;
  margin: 17px auto 0;
  color: #3faebf;
}
.payments .regular.pricing.strikethrough {
  opacity: 0.4;
}

/* This CSS is taken from the Stripe Elements Quickstart Guide (https://stripe.com/docs/stripe-js/elements/quickstart). 
   * It is used to style the container, not the actual inputs inside */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.payment-success {
  margin-top: 100px;
}
