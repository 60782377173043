#account .section {
  /*padding-bottom: 50px;*/
  font-size: 14px;
}
.section h3 {
  margin: 0 0 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #efefef;
}
#account .form-container {
  max-width: 440px;
}
#account .section-title {
  font-size: 20px;
  letter-spacing: -0.17px;
  margin-bottom: 32px;
}
#account .features {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
#account .features li:not(:last-child) {
  margin-bottom: 10px;
}
#account .support-pin .icon {
  cursor: pointer;
  max-width: 20px;
  margin-left: 10px;
}
#account .success-label {
  margin-left: 0;
  margin-right: 0;
}
