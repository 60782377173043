#verify {
  min-height: 100vh;
}
#verify .container {
  max-width: 500px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
