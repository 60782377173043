.table-item {
  transition: background-color 150ms ease-in-out 0s;
  font-size: 16px;
  color: #000000;
  border-top: 1px solid #efefef;
  padding: 20px;
  position: relative;
}

.table {
  border: 1px solid #efefef;
  border-radius: 8px;
}
