/*@import "../../../node_modules/codemirror/lib/codemirror.css";*/

/* Pages */
#pages.content-window {
  padding-top: 10px;
}

#pages .retreiving-data {
  position: sticky;
  top: 165px;
  padding-bottom: 20px;
  background-color: #ffffff;
  z-index: 2;
}
#pages .refresh {
  justify-content: flex-start;
  position: sticky;
  top: 156px;
  background-color: #ffffff;
  margin: 0;
  padding: 10px 0 20px;
  z-index: 2;
}
#pages .refresh img {
  max-width: 24px;
  cursor: pointer;
}
#pages .refresh img:hover {
  opacity: 1;
}
#pages .last-cached {
  margin-left: 15px;
  font-size: 12px;
}
#pages .filters > * {
  font-size: 12px;
}
#pages .filters > *:not(:last-child) {
  margin-right: 15px;
}
#pages .filters {
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  position: sticky;
  top: 80px;
  background-color: #ffffff;
  padding-bottom: 25px;
  padding-top: 30px;
  z-index: 2;
}
#pages .summary {
  display: inline-block;
  font-size: 12px;
  color: #d64b33;
}
#pages .summary .strong {
  font-weight: bold;
}
#pages .table-container {
  overflow-x: auto;
  margin-top: 49px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: none;
}
#pages .table {
  min-width: 768px;
}
ul.pages-container {
  padding: 0;
  margin: 0;
}
#pages .page-item {
  border-top: 1px solid #efefef;
  border-bottom: none;
  transition: background-color 150ms ease-in-out 0s;
  position: relative;
  padding: 15px;
  cursor: pointer;
}
.page-item input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
/*
.page-item input[type="checkbox"] {
  opacity: 0;

  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 2;
}
.page-item input[type="checkbox"] + label {
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-rows: auto auto;
  align-items: center;
  -moz-column-gap: 1em;
  column-gap: 1em;
  position: relative;
  cursor: pointer;
}
.page-item input[type="checkbox"]:checked + label::before {
  background-color: #3faebf;
}
.page-item input[type="checkbox"] + label::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid #8f94a1;
  background: transparent;
}
.page-item input[type="checkbox"]:checked + label::after {
  transform: scale(1);
}
.page-item input[type="checkbox"] + label::after {
  content: "\2713";
  color: white;
  font-size: 1.25rem;
  position: absolute;
  top: 0px;
  left: 4px;
  transform: scale(0);
  transition: all 300ms ease-in-out;
}
*/

#pages .page-item:first-child {
  border: none;
}
#pages .page-item.active,
#pages .page-item.selected {
  background-color: rgba(63, 174, 191, 0.1);
  border-left: 3px solid #3faebf;
}
#pages .page-item:last-child {
  border-bottom: 1px solid #efefef;
}
#pages .page-item:not(.active):hover {
  background-color: rgba(63, 174, 191, 0.1);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
}
#pages .page-item:not(.active):hover .type,
#pages .page-item:not(.active):hover .updated-date {
  opacity: 0.2;
}
#pages .page-item .page-title {
  margin: 0;
  width: 80%;
  font-size: 14px;
  font-weight: 400;
  padding-right: 15px;
}
#pages .page-item .page-title a {
  /*font-weight: 600;*/
  color: #000000;
}
#pages .page-item:hover .page-title a {
  text-decoration: underline;
  color: #3faebf;
}
#pages .page-item .page-info {
  justify-content: flex-start;
}
#pages .page-item .status-info > * {
  display: inline-block;
  vertical-align: middle;
}
#pages .page-item .status-dot {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
#pages .page-item .publish-status {
  width: auto;
  margin-right: 20px;
}
#pages .page-item .status {
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
}
#pages .page-item .status .warning {
  vertical-align: middle;
  width: 15px;
  padding: 0;
  margin: 0 10px;
  cursor: pointer;
  font-weight: 300;
  text-transform: none;
}
#pages .page-item .schema-status {
  font-size: 10px;
}
#pages .page-item .btn-container {
  width: 40%;
  text-align: right;
  justify-content: flex-end;
}
#pages .page-item .btn-container .btn:not(:last-child) {
  margin-right: 10px;
}
#pages .page-item .options-container {
  position: absolute;
  right: 0;
  width: auto;
  z-index: 1;
  padding: 20px;
}

#pages .page-item .options.opened {
  display: block;
}

/* Status labels */

#pages .page-item .status > *,
.pill-btn {
  display: inline-block;
  border-radius: 25px;
  padding: 7px 10px;
  line-height: 1;
  font-weight: 500;
  font-size: 12px;
  width: 85px;
  text-align: center;
}
.pill-btn.administrator {
  background-color: #d64b33;
  color: #ffffff;
}
.pill-btn.editor {
  background-color: #4bb543;
  color: #ffffff;
}
.status .published,
.status .published_ab,
.status .published_ab_variant {
  background-color: #4985e3;
  color: #ffffff;
}
.status .draft {
  background-color: #d64b33;
  color: #ffffff;
}
.status .no-schema {
  background-color: #7e7f73;
  background-color: rgba(126, 127, 115, 0.7);
  color: #ffffff;
}

/* type labels */
#pages .table-header .type {
  cursor: pointer;
}
#pages .page-item .type > * {
  font-size: 13px;
}
.type .label {
  text-transform: capitalize;
}

/* Table settings */
#pages .table-header {
  padding: 0 15px;
  position: sticky;
  top: 215px;
  background-color: #f9fbfc;
  z-index: 2;
  border: 1px solid #efefef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
#pages .table-header .status,
#pages .table-header .updated {
  cursor: pointer;
}
#pages .page-item .page-info .select,
#pages .table-header .select {
  margin-right: 16px;
  position: relative;
}
#pages .page-item .page-info,
#pages .table-header > *:first-child {
  width: 40%;
  justify-content: flex-start;
}
#pages .page-item .status,
#pages .table-header > *:nth-child(2) {
  width: 15%;
}
#pages .page-item .type,
#pages .table-header > *:nth-child(3) {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: block;
}

#pages .page-item .updated-date,
#pages .table-header > *:nth-child(4) {
  width: 25%;
  text-align: right;
}
#pages .page-item .updated-date {
  font-size: 13px;
}

/*
* Hover Menu
*/
#pages .page-item .hover-menu {
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out all 0.3s;
  position: absolute;
  height: 100%;
  width: 46%;
  right: 0;
  display: flex;
  align-items: center;
}
#pages .page-item:hover .hover-menu {
  opacity: 1;
  visibility: visible;
}
#pages .page-item .hover-menu > * {
  margin: 0 5px;
}

/* Table item options */
#pages .page-item .options {
  display: none;
  background: #ffffff;
  border: 1px solid #eff0f0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 15px;
  top: 50px;
  z-index: 2;
  min-width: 128px;
}
#pages .page-item .options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#pages .page-item .options li {
  cursor: pointer;
  padding: 7px 16px;
}
#pages .page-item .options li,
#pages .options a {
  color: rgba(0, 0, 0, 1);
}
#pages .options li:hover {
  background-color: rgba(62, 173, 190, 0.2);
  color: #000000;
}

#pages .options li:hover a {
  color: #000000;
}

/* Right Sidebar */
#pages .page-item.active .right-sidebar,
#pages.right-sidebar-enabled .right-sidebar.single {
  right: 0;
}
@media screen and (min-width: 501px) {
  .right-sidebar-enabled.content-window,
  #pages.right-sidebar-multi.content-window {
    margin-right: 266px;
  }
}

.content-window {
  position: relative;
  margin-right: 0;
  -webkit-transition: ease-in-out margin-right 0.2s;
  -moz-transition: ease-in-out margin-right 0.2s;
  -o-transition: ease-in-out margin-right 0.2s;
  transition: ease-in-out margin-right 0.2s;
}
.right-sidebar {
  width: 266px;
  position: fixed;
  height: 100%;
  right: -266px;
  top: 0;
  z-index: 2;
  overflow-y: auto;
  background-color: #fff;
  padding: 112px 26px 26px;
  border-left: 1px solid #efefef;
  -webkit-transition: ease-in-out right 0.2s;
  -moz-transition: ease-in-out right 0.2s;
  -o-transition: ease-in-out right 0.2s;
  transition: ease-in-out right 0.2s;
}
#pages .right-sidebar.multi {
  z-index: 2;
  right: 0;
}
#pages .right-sidebar .image-preview {
  padding: 16px 36px;
  background-color: #f9f9f9;
  margin-bottom: 32px;
}
#pages .right-sidebar .image-preview .title {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
.sidebar-options {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar-options li,
.sidebar-options > * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-weight: 500;

  padding: 15px 25px;
  width: calc(100% + 51px);
  position: relative;
  left: -26px;
  border-top: 1px solid #eaeaea;
}
.sidebar-options > *:first-child {
  border-top: none;
}
.sidebar-options > *:hover {
  background-color: rgba(63, 174, 191, 0.1);
  border-color: transparent;
}
.sidebar-options > *:hover + * {
  border-color: transparent;
}
.sidebar-options > *.disabled:hover {
  background-color: #dddddd;
}

.sidebar-options > * img {
  max-height: 20px;
  width: 20px;
  margin-right: 12px;
}

@media screen and (max-width: 500px) {
  #pages .filters {
    display: block;
  }
  #pages .filters > * {
    width: 100%;
    max-width: none;
    margin: 0 0 10px;
  }
  #pages .filters > *:not(:last-child) {
    margin-right: 0;
  }
  #pages .filters > *:last-child {
    margin: 0;
  }
  #pages .page-item .page-title,
  #pages .page-item .type > *,
  #pages .page-item .updated-date {
    font-size: 12px;
  }
}

/* 
* Page Detail 
*/
#page-detail {
  padding-bottom: 50px;
}
#page-detail #schema-found {
  width: calc(100% + 80px);
  margin-left: -40px;
  background-color: #7e7f73;
  color: #fff;
  padding: 16px 40px;
  margin-bottom: 36px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 2;
  align-self: flex-start;
}
#page-detail #schema-found > * {
  display: inline-block;
  vertical-align: middle;
}
#page-detail #schema-found .text {
  font-size: 14px;
}
#page-detail #schema-found .remove {
  width: 143px;
}
#page-detail .page-header {
  margin-top: 50px;
}
#page-detail .page-header h1 {
  font-size: 24px;
  letter-spacing: -0.2px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 0;
}
#page-detail .page-head .preview-content {
  margin-top: 30px;
  display: none;
}

#page-detail .top-bar .back > * {
  display: inline-block;
  vertical-align: middle;
}
#page-detail .top-bar .back .text {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
}

#page-detail .right-sidebar {
  right: 0;
  padding-top: 165px;
}

#page-detail .page-status > *,
#page-detail .publish-status > *,
#page-detail .page-status .creation > * {
  display: inline-block;
  vertical-align: middle;
}
#page-detail .schema-preview {
  max-width: 575px;
  margin: 0 auto;
}
#page-detail .status-dot {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
#page-detail .current-state .state {
  width: 70px;
  margin-right: 20px;
}
#page-detail .page-status {
  font-size: 12px;
  border: 1px solid rgb(223, 227, 235);
  background-color: #ffffff;
  transition: background-color 150ms ease-in-out 0s;
  margin-bottom: 35px;
}
#page-detail .page-status > *:not(.btn) {
  padding: 15px;
}
#page-detail .current-state {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
}
#page-detail .schema-status {
  font-size: 10px;
}
#page-detail .section-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}
#page-detail .section-desc {
  margin-top: 14px;
  font-size: 12px;
}

/*
* Page Head
*/
#page-detail .page-head .expand {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
#page-detail .page-head .accordion-toggle {
  padding: 10px;
  border-radius: 4px;
}
#page-detail .page-head.opened .accordion-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#page-detail .page-head.opened .expand {
  transform: translateY(-50%) rotate(180deg);
}
#page-detail .page-head .expand:hover path {
  fill: rgb(214, 130, 49);
}

.CodeMirror {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
#page-detail .user-input {
  padding: 30px 0;
}
#page-detail .user-input .form-container {
  max-width: 450px;
  margin: auto;
  font-size: 14px;
  padding: 50px 0;
}

#page-detail .schema-form {
  padding: 20px 20px 10px;
  font-size: 14px;
}
#page-detail
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
#page-detail select,
#page-detail textarea {
  margin: 10px auto;
  display: block;
  width: 100%;
}

#page-detail select[multiple] {
  height: auto;
  background-image: none;
}

#page-detail pre:not(.CodeMirror-line) {
  font-family: "IBM Plex Mono", monospace;
  background-color: #f5f8fa;
  transition: background-color 0.15s ease-in-out 0s;
  border: 1px solid #dfe3eb;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px 24px 8px 12px;
  overflow: auto;
  height: 45vh;
}
#page-detail pre.tag {
  height: auto;
}
#codePreview {
  height: 250px;
  font-family: "IBM Plex Mono", monospace;
  background-color: #f5f8fa;
  transition: background-color 0.15s ease-in-out 0s;
  border: 1px solid #dfe3eb;
  font-size: 12px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 8px 24px 8px 12px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0;
}

#page-detail .schema-input {
  padding: 42px 0 50px;
  align-items: flex-start;
}
#page-detail .schema-input > *:first-child {
  padding-right: 25px;
}
#page-detail .schema-input > *:last-child {
  padding-left: 25px;
  position: sticky;
  top: 150px;
}

.schema-input .removable {
  position: relative;
}
.schema-input .remove-item {
  position: absolute;
  right: 0;
  top: 50%;
  padding: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: bold;
  color: #3faebf;
  cursor: pointer;
}

#page-detail .page-head .buttons > * {
  margin: 10px;
}

#page-detail .buttons > * {
  margin: 0 10px;
}
#page-detail .buttons > *:first-child {
  margin-left: 0;
}
#page-detail .buttons > *:last-child {
  margin-right: 0;
}

#page-detail .right-sidebar .page-info {
  position: relative;
}
#page-detail .right-sidebar .page-info::after {
  content: "";
  height: 1px;
  width: calc(100% + 52px);
  background-color: #efefef;
  display: block;
  position: relative;
  left: -26px;
}
#page-detail .right-sidebar .page-info > * {
  margin-bottom: 30px;
}
#page-detail .right-sidebar h3 {
  margin: 0 0 7px;
  color: #bbbbbb;
  font-size: 12px;
}
#page-detail .right-sidebar .text {
  font-size: 14px;
}
#page-detail .status span {
  display: inline-block;
  border-radius: 13px;
  padding: 7px 12px;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}
#page-detail .right-sidebar .sidebar-options {
  font-weight: 500;
}

/* Test Results */
#page-detail .test-results {
  background-color: #f5f8fa;
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  margin: 35px 0;
}
#page-detail .test-results .please-wait {
  padding: 30px 20px;
}
#page-detail .test-results .title {
  margin: 0;
  background-color: #0b263a;
  color: #ffffff;
  padding: 11px;
  text-transform: uppercase;
  letter-spacing: 0.27px;
  font-weight: 600;
}
#page-detail .test-results .table-head > *,
#page-detail .test-results .table-body > * {
  width: calc(100% / 5);
  margin: 0;
  padding: 15px;
}
#page-detail .test-results .table-head > *:first-child,
#page-detail .test-results .table-body > *:first-child {
  margin-left: 20px;
}
#page-detail .test-results .table-head > *:last-child,
#page-detail .test-results .table-body > *:last-child {
  margin-right: 20px;
}
#page-detail .test-results .table-body {
  border-top: 1px solid #e1e4e6;
  font-size: 12px;
}
#page-detail .test-results .table-body > *:last-child .tag {
  border-radius: 13px;
  font-weight: 500;
  font-size: 12px;
  padding: 6px 16px;
  line-height: 1;
  text-align: center;
  display: inline-block;
}
#page-detail .test-results .table-body > *:last-child .tag.pass {
  background: #1fad9d;
  color: #ffffff;
}
#page-detail .test-results .table-body > *:last-child .tag.failed {
  background: #d64b33;
  color: #ffffff;
}

#page-detail .test-results .table-head > * #page-detail .test-summary > * {
  padding: 0 15px;
}

#page-detail .test-summary > *:first-child {
  padding-left: 0;
}
#page-detail .test-summary > *:last-child {
  padding-left: 0;
}

#page-detail .test-results .analysis {
  padding: 10px 0;
}
#page-detail .test-results .success-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
#page-detail .test-results .error-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}
#page-detail .test-results .error-item .MuiSvgIcon-root,
#page-detail .test-results .success-item .MuiSvgIcon-root {
  margin-right: 5px;
}
#page-detail .test-results .error-item .index {
  width: 20px;
}
#page-detail .errors {
  margin: 15px 35px;
}
#page-detail .errors h5 {
  margin-top: 0;
}
#page-detail .warnings {
  margin-top: 36px;
  display: inline-block;
  padding: 13px 15px;
  position: relative;
  background-color: rgba(214, 75, 54, 0.05);
  border: 1px solid #d64b33;
  color: #d64b33;
  font-size: 12px;
  /*
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  */
  border-radius: 5px;
  text-align: center;
}
#page-detail .warnings img {
  margin-right: 10px;
}
#page-detail .warnings > * {
  display: inline-block;
  vertical-align: middle;
}
.serp-preview > * {
  margin: 0 auto;
}
.schema-fields-drawer {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eaeaea;
}
.schema-fields-drawer .content {
  background-color: #ffffff;
  z-index: 2;
  position: relative;
}
.schema-fields-drawer:not(:last-child) {
  margin-bottom: 15px;
}
.schema-fields-drawer.closed .content {
  display: none;
}
.schema-fields-drawer:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
.schema-fields-drawer .title {
  position: relative;
  margin: 0;
  padding: 10px;
  text-transform: initial;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.schema-fields-drawer .draggable {
  margin-right: 10px;
  cursor: move;
}
.schema-fields-drawer .draggable:hover path {
  fill: rgb(214, 130, 49);
}
.schema-fields-drawer .delete {
  position: absolute;
  cursor: pointer;
  right: 45px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.schema-fields-drawer .delete:hover path {
  fill: red;
}

.schema-fields-drawer.closed .title .expand {
  transform: rotate(0);
  top: 25%;
}
.schema-fields-drawer .title .expand {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 10px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%) rotate(180deg);
}
.schema-fields-drawer .expand:hover path {
  fill: rgb(214, 130, 49);
}
.schema-fields-drawer.closed .title .indicator {
  transform: rotate(0);
  top: 40%;
}

.schema-fields-drawer .title .indicator {
  content: "";
  cursor: pointer;
  background-image: url("../../assets/down-triangle-white@2x.png");
  width: 12px;
  display: block;
  height: 7px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin: 0 10px;
  transform: translateY(-50%) rotate(180deg);
  transition: ease-in-out transform 0.3s;
}
.schema-fields-drawer .remove-schema {
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px 20px;
  cursor: pointer;
  display: inline-block;
}

.link {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  transition: ease-in-out all 0.2s;
}

#page-detail .remove-item {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}

.faq-fields,
.sameas-fields,
.contactpoint-fields,
.repeater-container {
  position: relative;
}
.faq-fields .remove-item,
.sameas-fields .remove-item,
.contactpoint-fields .remove-item,
.repeater-container .remove-item {
  top: 10px;
}

#main-content .top-bar {
  -webkit-transition: ease-in-out padding 0.2s;
  -moz-transition: ease-in-out padding 0.2s;
  -o-transition: ease-in-out padding 0.2s;
  transition: ease-in-out padding 0.2s;
}
#main-content.expanded .top-bar {
  padding-left: 240px;
}

.suggestion-container {
  display: inline-block;
}
#schema-suggestion {
  font-size: 12px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 20px;
  justify-content: flex-start;
  display: inline-block;
}
#schema-suggestion * {
  display: inline-block;
}
#schema-suggestion span {
  margin-left: 5px;
}
#schema-suggestion .icon {
  max-width: 15px;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.tabs > * {
  padding: 5px 10px;
  border: 1px solid #eaeaea;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}
.tabs > *.active {
  background-color: #3faebf;
  color: #fff;
  border-color: #3faebf;
}
.tabs > *:not(:last-child) {
  border-right: 0;
}
.tab-content-container {
  border-top: 1px solid #eaeaea;
}
.tab-content-container > * {
  display: none;
}
.tab-content-container > *:first-child {
  display: block;
}

#page-detail .advanced-input textarea {
  font-family: "IBM Plex Mono", monospace;
  background-color: #f5f8fa;
  transition: background-color 0.15s ease-in-out 0s;
  border: 1px solid #dfe3eb;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px 24px 8px 12px;
  overflow: auto;
  height: 45vh;
}

#page-detail iframe {
  width: 100%;
  height: 75vh;
}

.revisions {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.revisions .revision-item:first-child {
  border: none;
}
.revisions .revision-item {
  border-top: 1px solid #efefef;
  border-bottom: none;
  transition: background-color 150ms ease-in-out 0s;
  position: relative;
  padding: 15px;
  cursor: pointer;
  min-width: 300px;
}
.revisions .revision-item:not(.active):hover {
  background-color: rgba(63, 174, 191, 0.1);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 12%);
}
.revision-item .revert {
  position: absolute;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.revisions .revision-item:not(.active):hover .revert {
  display: block;
}
.revision-item .updated {
  font-weight: 500;
}
.revision-item .published-by {
  margin-top: 5px;
}
.revision-item .current-version {
  margin-top: 5px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  background-color: rgb(214, 130, 49, 0.3);
}
