/* Buttons */
.MuiPaper-root {
  padding: 30px 20px;
}
.MuiPaper-root.MuiAccordion-root {
  padding: 15px 10px;
}

.MuiIconButton-colorPrimary {
  color: #3faebf !important;
}

.MuiButton-root {
  min-height: 0 !important;
  line-height: 1 !important;
}
.MuiButton-root.primary,
.MuiButton-root.secondary,
.MuiButton-contained.primary {
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  transition: ease-in-out all 0.3s;
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  box-shadow: none;
  text-transform: initial;
}

.MuiButton-contained.primary,
.MuiButton-root.primary {
  background-color: rgb(214, 130, 49);
  color: #ffffff;
}

.MuiButton-root.secondary {
  border-color: rgb(214, 130, 49);
  color: #c47335;
}

.MuiButton-contained.primary:hover,
.MuiButton-root.primary:hover,
.MuiButton-root.secondary:hover {
  /*background-color: rgb(214, 130, 49);*/
  background-color: #c0752c;
  color: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 6%), 0px 2px 22px rgb(0 0 0 / 4%),
    0px 10px 17px rgb(0 0 0 / 10%);

  /* transform: translateY(-2px); */
}

.MuiIconButton-root.text-on-hover.MuiIconButton-colorPrimary {
  transition: ease-in-out all 0.2s;
}

.MuiIconButton-root.text-on-hover.MuiIconButton-colorPrimary:hover {
  background-color: #3faebf !important;
  color: #ffffff !important;
  border-radius: 50px;
}

.MuiPaper-root .section-title {
  position: relative;
}

.MuiIconButton-root.text-button.MuiIconButton-colorPrimary {
  border-radius: 50px;
  position: absolute;
  top: -10px;
  right: 0;
  padding: 5px 10px;
}
.MuiIconButton-root.text-button.MuiIconButton-colorPrimary:hover {
  background-color: #3faebf !important;
  color: #ffffff !important;
}

.MuiIconButton-root.text-button .text {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 5px;
}

.MuiIconButton-root.pill-button,
.pill-button {
  border-radius: 50px;
  padding: 6px 12px;
  transition: ease-in-out all 0.3s;
  height: 30px;
}
.MuiIconButton-root.pill-button .text,
.pill-button .text {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}
.MuiIconButton-root.pill-button.MuiIconButton-colorPrimary {
  background-color: #3faebf !important;
  color: #ffffff !important;
}

.MuiIconButton-root.pill-button.MuiIconButton-colorSecondary {
  background-color: rgb(214, 130, 49) !important;
  color: #ffffff !important;
}
.MuiIconButton-root.pill-button.MuiIconButton-colorPrimary:hover,
.MuiIconButton-root.pill-button.MuiIconButton-colorSecondary:hover {
  background-color: #0b263a !important;
}

.MuiIconButton-root.pill-button.disabled,
.pill-button.disabled,
.MuiIconButton-root.pill-button.disabled:hover,
.pill-button.disabled:hover {
  background-color: #eaeaea !important;
  color: black !important;
}

.text-on-hover .text {
  display: none;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 5px;
}
.text-on-hover:hover .text {
  display: block;
}

.btn.gradient {
  overflow: hidden;
  background-color: transparent;
  position: relative;
}
.btn.gradient:after {
  position: absolute;
  content: "";
  display: inline-block;
  background-color: #ffac81;
  background-image: linear-gradient(315deg, #ffac81 0%, #ff928b 74%);
  height: 150%;
  width: 150%;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  margin: 0 auto;
  transform: translate(-10px, -50%);
  transition: ease-in-out all 0.4s;
}
.btn.gradient:hover:after {
  height: 300%;
  width: 300%;
}
.btn > span {
  position: relative;
}

.btn > * {
  display: inline-block;
  vertical-align: middle;
}
.btn > *:not(:last-child) {
  margin-right: 5px;
}
.btn-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: ease-in-out all 0.3s;
  height: 18px;
  width: 18px;
}

.clickable {
  cursor: pointer;
}

.btn-icon img {
  max-height: 100%;
}

.close-btn {
  cursor: pointer;
}

.option-btn {
  opacity: 1;
}
.option-btn:hover path#Options-Icon {
  fill: #3faebf;
}

.btn .MuiSvgIcon-root {
  margin-right: 5px;
}

@media screen and (max-width: 500px) {
  .btn-icon {
    height: 15px;
    width: 15px;
  }
}
